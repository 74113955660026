
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import Layout from "../components/Layout";
import { getHeader } from "../services";
import CookieConsent from "../components/CookieConsent";

const Home = ({ homeProps }) => {
  let { t } = useTranslation();
  const router = useRouter();
  return (
    <>
      <Layout className={router.locale === "fa" ? "farsi" : "english"}>
        <Head>
          <title>{t("main:pageTitle")}</title>
          <meta
            name="description"
            content="Welcome to EhsanSafari's website. In this website, we demonstrate some projects & job experiences & photo gallery &..."
          />
        </Head>
        <div className="flex justify-center items-center">
          <section>
            <div className="container">
              <div className="background-img h-96 relative transform transition-all translate-y-12 ease-out">
                <div className="box">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <div className="content relative group">
                    <Image
                      src={homeProps?.avatar?.url}
                      className="shadow-lg my-0 mx-auto shadow-lg opacity-80 hover:opacity-100 object-cover w-full aspect-square group-hover:scale-110 transition duration-300 ease-in-out"
                      alt={homeProps.mainTitle}
                      width={100}
                      height={100}
                      layout="fixed"
                    />
                    <h1 className="text-gray-200 text-2xl text-center mt-2 tracking-wide">
                      {homeProps.mainTitle}
                    </h1>
                    <h2 className="text-gray-500 text-base text-center">
                      {homeProps?.subTitle}
                    </h2>
                    <div className="w-full flex justify-center pt-5 pb-5">
                      <a
                        href="https://github.com/safariehsan"
                        className="mx-5"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div aria-label="Github" role="img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#718096"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-github"
                          >
                            <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                          </svg>
                        </div>
                      </a>
                      <a
                        href="https://linkedin.com/in/safariehsan"
                        className="mx-5"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div aria-label="Twitter" role="img">
                          <svg
                            width="26"
                            height="26"
                            fill="#718096"
                            viewBox="0 0 1024 1024"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                          </svg>
                        </div>
                      </a>
                      <a
                        href="https://www.instagram.com/rahkarino/"
                        className="mx-5"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div aria-label="Instagram" role="img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#718096"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-instagram"
                          >
                            <rect
                              x="2"
                              y="2"
                              width="20"
                              height="20"
                              rx="5"
                              ry="5"
                            ></rect>
                            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
      <CookieConsent />
    </>
  );
};

export default Home;

 async function _getStaticProps({ locale }) {
  const homeProps = await getHeader(locale);
  return {
    props: { homeProps },
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  